<template>
    <div>
        <Header backType="blank" :back="true">
            <span slot="headText" style="font-size:.17rem;">补充文件</span>
        </Header>
        <div class="normalInputRow">
            <MInput
                v-if="event.tenant_code == 'pfizer'"
                title="实际人数"
                pla="请输入实际人数"
                v-model="qty"
            ></MInput>
        </div>
        <AttentionTips v-if="['novartis'].includes(event.tenant_code)" :tips="tips" :newUI="true" />
        <div class="upload-sub-title" v-if="orderDetail.itemType == '5'">补充水单</div>
        <div class="paymentInvoice" v-if="orderDetail.itemType == '5'">
            <viewer :images="waterFilesList" class="imgContainer imgContainer_new">
                <template v-for="(list) in waterFilesList">
                    <div :key="list.url">
                        <p v-if="list.fileType == '.ofd'"  @click="() => openOfd(list.url)">
                            <img src="../../assets/img/ofd.png" style="width:.6rem;height:.6rem"/>       
                        </p>
                        <p v-else-if="list.fileType == '.pdf'" @click="() => openPdf(list.url)">
                            <i-icon style="width:.6rem;height:.6rem" class="icon-pdf" color="var(--themeColor)" name="icon-pdf" />
                        </p>
                        <img v-else :src="list.url" alt/>
                        <Svgs
                            color='#F37600'
                            classes="paymentDelete"
                            v-if="showBtn"
                            name="iconjiaobiaoshanchu"
                            @click.native="deleteInvoice(list, 'water')"
                        ></Svgs>
                    </div>
                </template>
                <div 
                    class="add_div"
                    v-if="showBtn"
                    @click="openBtnGroup('water')">
                    <Svgs
                        classes="paymentAdd"
                        color='#B9BABB'
                        name="iconhao"
                    ></Svgs>
                </div>
            </viewer>
        </div>
        <div v-if="!['novartis'].includes(event.tenant_code)">
            <div class="upload-sub-title">补充发票</div>
            <div class="paymentInvoice">
                <viewer :images="waterFilesList" class="imgContainer imgContainer_new">
                    <template v-for="(list) in invoiceFilesList">
                        <div :key="list.url">
                            <p v-if="list.fileType == '.ofd'"  @click="() => openOfd(list.url)">
                                <img src="../../assets/img/ofd.png" style="width:.6rem;height:.6rem"/>       
                            </p>
                            <p v-else-if="list.fileType == '.pdf'" @click="() => openPdf(list.url)">
                                <i-icon style="width:.6rem;height:.6rem" class="icon-pdf" color="var(--themeColor)" name="icon-pdf" />
                            </p>
                            <img v-else :src="list.url" alt/>
                            <!-- <img :src="list.url" alt/> -->
                            <Svgs
                                color='#F37600'
                                classes="paymentDelete"
                                name="iconjiaobiaoshanchu"
                                @click.native="deleteInvoice(list, 'invoice')"
                            ></Svgs>   
                        </div>
                    </template>
                    <div 
                        class="add_div" 
                        @click="openBtnGroup('invoice')">
                        <Svgs
                            classes="paymentAdd"
                            color='#B9BABB'
                            name="iconhao"
                        ></Svgs>
                    </div>
                </viewer>
            </div>
        </div>
        
        <Btn btnClass="normalBtn invoiceSubmit" v-if="event.tenant_code == 'pfizer'" @callBack="submitInvoice">
            <span slot="btnContent">提交</span>
        </Btn>
        <BtnGroup ref="btnGroup" :btnList="btnList" />
        <MessageBox ref="remove" type="tips" messageTxt="确定删除图片？" @onOk="remove"></MessageBox>
    </div>
</template>

<script>
import pdfPopup from '@/components/PdfPopup/plugin.js'
import { miceService } from "@/service/miceService.js";
import { mapGetters,mapMutations } from "vuex";
import { saveQty } from "@/config/uploadInvocie.js";
import BtnGroup from "./BtnGroup";
import { UploadPlugOCR,UploadPlug} from "smart-filer-vue";
import { SmartStorage } from 'smart-core-util'
import { Toast } from 'mint-ui';
const uploadInstance = new UploadPlugOCR({
  host: process.env.VUE_APP_GATEWAY
});
const uploadInstance2 = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY
});
export default {
    name: "UpdateFile",
    computed: {
        ...mapGetters(["event", "orderDetail"]),
        showBtn(){
            if(['novartis'].includes(this.event.tenant_code)){
                var d = new Date(this.orderDetail.dtStart);
                var todaysDate = new Date();
                if(d.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)){
                    return true;
                } else {
                    return false;
                }
            }else{
                return true
            }
        }
    },
    components: {
        BtnGroup
    },
    data() {
        return {
            qty: "",
            waterFilesList: [],
            invoiceFilesList: [],
            btnList: [
                {
                    txt: "拍照",
                    callback: this.graph
                },
                {
                    txt: "从相册选取",
                    callback: this.album
                }
            ],
            type: '',
            address: null,
            tips:['补上传结算水单，仅限用餐当日晚上24:00前上传；']
        };
    },
    mounted() {
        this.qty = this.orderDetail.actualQty || this.orderDetail.realQty
        this.upLoadInvoice()
        this.getWaterFiles();
        this.getInvoiceFiles();
    },
    methods: {
        ...mapMutations([
            'ORDER_DETAIL',
        ]),
        openPdf(pdfUrl){
            this.iJsBridge.openPdfImg(pdfUrl)
        },
        openOfd(){
            Toast('暂不支持预览Ofd格式文件');
        },
        getWaterFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        catalog: "水单",
                        processId: this.orderDetail.proposalId,
                        relatedId: this.orderDetail.itemId,
                        relatedType: "settle_supplement"
                    }
                },
                callback: data => {
                    this.waterFilesList = data.content
                }
            })
        },
        getInvoiceFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        catalog: "发票",
                        itemId: this.orderDetail.itemId,
                        processId: this.orderDetail.proposalId,
                        relatedType: "Invoice_supplement"
                    }
                },
                callback: data => {
                    this.invoiceFilesList = data.content
                }
            })
        },
        async openBtnGroup(type) {
            if(type != 'water'){
                this['ORDER_DETAIL']({...this.orderDetail,addFile: true});
                this.$router.push({ path: '/uploadInvoice' })
                return
            }
            this.type = type
            if(window.flutter_inappwebview){
                let uploadFileData = {
                    "hideCamera": false,
                    "hideGallery": false,
                    "hidePdfAndOfd": true
                }
                this.iJsBridge.uploadFile(uploadFileData,this.mappingAsync)
                return
            }
            this.$refs.btnGroup.openPop();
        },
        //拍照
        graph() {
            this.$refs.btnGroup.openPop();
            let _this = this
            uploadInstance2.cameraFile({
                callback: data => {
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                            _this.mappingAsync(result)
                        }
                    })
                }
            })
        },
        //相册选取
        album() {
            this.$refs.btnGroup.openPop();
            let _this = this
            uploadInstance.localUpload({
                callback: data => {
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                           _this.mappingAsync(result)
                        }
                    })
                }
            })
        },
        mappingAsync(result) {
            let mappingData
            if(this.type === 'water') {
                mappingData = {
                    catalog: "水单",
                    processId: this.orderDetail.proposalId,
                    relatedId: this.orderDetail.itemId,
                    relatedType: "settle_supplement"
                }
                if(this.address){
                    let auditData = {
                        province: this.address.province,
                        city: this.address.city,
                        address: this.address.address,
                    }
                    mappingData.auditData = JSON.stringify(auditData)
                }
            } else {
                mappingData = {
                    catalog: "发票",
                    itemId: this.orderDetail.itemId,
                    processId: this.orderDetail.proposalId,
                    relatedType: "Invoice_supplement",
                }
            }
            uploadInstance.mappingAsync({
                fileKeys: [result[0].content.fileKey],
                mappingData: mappingData,
                callback: () => {
                    if(this.type === 'water') {
                        this.getWaterFiles()
                    } else {
                        this.getInvoiceFiles()
                    }
                }
            })
        },
        async upLoadInvoice() {
            var map = new AMap.Map("container", {});
            AMap.plugin("AMap.Geolocation", () => {
                /* global AMap */
                var geolocation = new AMap.Geolocation({
                    timeout: 3000, //超过n秒后停止定位，默认：5s
                    useNative: true,
                    enableHighAccuracy: true, //是否使用高精度定位，默认:true
                    extensions: 'all'
                });
                map.addControl(geolocation);
                geolocation.getCurrentPosition((status, result) => {
                    console.log('定位信息',status, result);
                    if (status == "complete") {
                        let address = {
                            province: result.addressComponent.province,
                            city: result.addressComponent.city || result.addressComponent.province,
                            address: (result.pois || [{name: ''}])[0].name || result.formattedAddress,
                        }
                        this.address = address
                    }
                })
            })
        },
        deleteInvoice(list, type) {
            this.type = type
            this.list = list
            this.$refs.remove.openPop();
        },
        remove() {
            uploadInstance.deleteAsync({
                fileItem: {
                    fileKey: this.list.fileKey
                },
                bucketAlias: "SmartEvent",
                callback: () => {
                    if(this.type === 'water') {
                        this.getWaterFiles()
                    } else {
                        this.getInvoiceFiles()
                    }
                }
            })
        },
        submitInvoice() {
            let params = {
                itemId: this.orderDetail.itemId,
                proposalId: this.orderDetail.proposalId,
                qty: this.qty,
            };
            let validateResult = this.validate(params, saveQty);
            if (!validateResult) return;
            miceService.saveQtyExpenses(params).then((res) => {
                if (res && res.success) {
                    this.$router.push({
                        path: "/orderList",
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "../OrderSettle/OrderSettle.scss";

.invoiceSubmit {
    margin-top: 0.1rem !important;
}

.paymentBtnRow {
    margin-bottom: 0 !important;
}



</style>